<template>
  <standard-layout :condition="order != null">
    <template v-slot:heading>
      <h1>Vielen Dank für Deine Bestellung, {{ order.customer.firstName }}</h1>
    </template>
    <template v-slot:main>
      <h5>
        Bestellnummer: {{ order.number }}
      </h5>
      <div class="row">
        <div class="col-12">
          <table class="table">
            <tr v-for="pos in order.positions" :key="pos.id">
              <td>{{ pos.quantity }}&times;</td>
              <td class="w-100">
                {{ pos.article.product.name }}
                <p class="small text-muted" v-if="pos.article.product.category === 'RES'">
                  {{ order.reservation.startTime | dateTime }} |
                  {{ order.reservation.duration }}h |
                  {{ pos.article.product.attendees }} Teilnehmer
                </p>
                <p class="small text-muted" v-if="pos.article.product.category === 'VOU' && pos.attendees > 0">
                  {{ pos.attendees }} Teilnehmer
                </p>
              </td>
              <td class="text-right">{{ pos.totalGrossPrice | price(2) }}</td>
            </tr>
            <tr v-if="order.totalGrossDiscount > 0">
              <td colspan="2">Rabatt/Gutschein</td>
              <td class="text-danger">{{ -order.totalGrossDiscount | price(2) }}</td>
            </tr>
            <tr>
              <td colspan="2" class="text-right">Total</td>
              <td class="font-weight-bold">{{ order.totalGrossPrice | price(2) }}</td>
            </tr>
            <tr>
              <td colspan="2" class="text-right">enthaltene MwSt.</td>
              <td>{{ order.totalVat | price(2) }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <span v-if="voucher">Der Gutschein und die Rechnung werden </span>
          <span v-else>Die Rechnung wird </span>
          in Kürze an {{ order.customer.email }} verschickt.
        </div>
      </div>
      <div class="row mt-4 border-top pt-3">
        <contact-us class="col-12 text-center">
          Noch Fragen?
        </contact-us>
      </div>
    </template>
  </standard-layout>
</template>

<script>
import ContactUs from "@/components/ContactUs";

export default {
  components: {ContactUs},
  props: {
    orderId: String
  },
  data() {
    return {
      order: undefined
    }
  },
  computed: {
    justConverted(){
      return this.$route.params.justConverted
    },
    voucher(){
      return this.order?.positions.findIndex(p => p.article.product.voucher) >= 0
    }
  },
  activated() {
    this.$api.get(`/orders/${this.orderId}`)
        .then(response => {
          this.order = response.data
          if(this.justConverted)
            this.$gtag.event('purchase',
                {
                  transaction_id: this.order.number,
                  affiliation: 'SPABoot Website',
                  value: this.order.totalGrossPrice,
                  currency: 'EUR'
                })
        })
        .catch((err) => {
          this.handleError(err, true)
          this.showError("Die Bestellung konnte nicht geladen werden.")
          this.$router.push("/")
        })
  }
}
</script>
